import React from "react"
import { graphql, Link } from 'gatsby';
import Seo from '../common/seo';
import 'tachyons';
import '../common/styles/custom.tachyons.css';

export default ({ data }) => {
  return (
    <React.Fragment>
        <Seo
          title={"닥터곽의 코스모스"}
          description={data.site.siteMetadata.description}
          naver={data.site.siteMetadata.naver_site_verification} />

        <div className="cf vh-100 bg-light-blue">

          <div className="fl dt vh-50 vh-100-ns w-100 w-50-ns bg-light-pink tc">
            <div className="dtc tc v-btm v-mid-ns tr-ns">
              <div className="mb3 mr5-ns">
                <Link to="/indexhome"
                  className="sans-serif link dark-blue" >
                  <h1>인터넷 접속</h1>
                </Link>
                <p className="sans-serif dark-blue">www.doctorkwak.com</p>
              </div>
            </div>
          </div>

          <div className="fl dt vh-50 vh-100-ns v-mid w-100 w-50-ns bg-blue tc">
            <div className="dtc tc v-top v-mid-ns tl-ns">
              <div className="mt3 ml5-ns">
                <a className="sans-serif link washed-red" 
                  href="http://192.168.1.222:80">
                  <h1>느루요양병원 원내 접속</h1>
                </a>
                <p className="sans-serif washed-red">입원 환자 정보 제공</p>
              </div>
            </div>
          </div>

        </div>
    </React.Fragment>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        naver_site_verification
      }
    }
  }
`
